<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="区域名称" prop="areaName"  >
        <a-input v-model="form.areaName" placeholder="请输入区域名称" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="区域全程" prop="fullName" >
        <a-input v-model="form.fullName" placeholder="请输入区域全程" :disabled="true" />
      </a-form-model-item>
<!--      <a-form-model-item label="上级ID" prop="parentId" >
        <a-input v-model="form.parentId" placeholder="请输入上级ID" />
      </a-form-model-item>-->
      <!--热门城市-->
      <a-form-model-item label="是否热门" prop="isHot">
        <a-switch v-model="form.isHot" />{{ form.isHot ? '是' : '否' }}
      </a-form-model-item>
      <a-form-model-item label="是否显示" prop="visible">
        <a-radio-group v-model="form.isShow" button-style="solid">
          <a-radio-button v-for="(d, index) in isShowOptions" :key="index" :value="d.value">{{ d.label }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item label="${comment}" prop="level" >
        <a-input v-model="form.level" placeholder="请输入${comment}" />
      </a-form-model-item>
      <a-form-model-item label="中心点的经纬度" prop="centerLatLon" >
        <a-input v-model="form.centerLatLon" placeholder="请输入中心点的经纬度" />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getArea, addArea, updateArea } from '@/api/extra/area'
import {mapGetters} from 'vuex'

export default {
  name: 'CreateForm',
  props: {
    isShowOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        areaId: null,

        areaName: null,

        fullName: null,
        isHot: null,
        isShow: null,
        parentId: null,

        level: null,

        centerLatLon: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        areaId: null,

        areaName: null,

        fullName: null,
        isHot: null,
        isShow: null,
        parentId: null,

        level: null,

        centerLatLon: null

      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const areaId = row ? row.areaId : ids
      getArea(areaId).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.areaId !== undefined && this.form.areaId !== null) {
            updateArea(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addArea(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
