<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="区域名称" prop="areaName">
                <a-input v-model="queryParam.areaName" placeholder="请输入区域名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="区域全称" prop="fullName">
                <a-input v-model="queryParam.fullName" placeholder="请输入区域全程" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
<!--              <a-col :md="8" :sm="24">
                <a-form-item label="上级ID" prop="parentId">
                  <a-input v-model="queryParam.parentId" placeholder="请输入上级ID" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="层次" prop="level">
                  <a-input v-model="queryParam.level" placeholder="层次" allow-clear/>
                </a-form-item>
              </a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="中心点的经纬度" prop="centerLatLon">
                  <a-input v-model="queryParam.centerLatLon" placeholder="请输入中心点的经纬度" allow-clear/>
                </a-form-item>
              </a-col>
              <!--热门城市-->
              <a-col :md="5" :sm="15">
                <a-form-item label="热门城市" prop="isHot">
                  <a-select placeholder="请选择是否热门" style="width:100%" v-model="queryParam.isHot" allowClear>
                    <a-select-option value="true">是</a-select-option>
                    <a-select-option value="false">否</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['extra:area:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['extra:area:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['extra:area:remove']">
          <a-icon type="delete" />删除
        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['extra:area:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :isShowOptions="dict.type['sys_show_hide']"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="areaId"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['extra:area:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['extra:area:edit']">
            <a-icon type="edit" />修改
          </a>
<!--          <a-divider type="vertical" v-hasPermi="['extra:area:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['extra:area:remove']">
            <a-icon type="delete" />删除
          </a>-->
        </span>
        <span slot="isHot" slot-scope="text, record">
            <!-- 重写自定义了个字典组件（下架）-->
           <custom-dict-tag :options="customDict.TFEnum" :value="record.isHot"/>
        </span>
        <template slot="isShow" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_show_hide']" :value="record.isShow" />
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listArea, delArea } from '@/api/extra/area'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'Area',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  dicts: ['sys_show_hide'],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        areaName: null,
        fullName: null,
        parentId: null,
        level: null,
        isHot:null,
        centerLatLon: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: '区域id',
          dataIndex: 'areaId',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '区域名称',
          dataIndex: 'areaName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '区域全程',
          dataIndex: 'fullName',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '上级ID',
          dataIndex: 'parentId',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '层次',
          dataIndex: 'level',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '中心点的经纬度',
          dataIndex: 'centerLatLon',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否热门城市',
          dataIndex: 'isHot',
          ellipsis: true,
          scopedSlots: { customRender: 'isHot' },
          align: 'center'
        },
        {
          title: '是否展示',
          dataIndex: 'isShow',
          ellipsis: true,
          scopedSlots: { customRender: 'isShow' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询省市区地区信息列表 */
    getList () {
      this.loading = true
      listArea(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        areaName: undefined,
        fullName: undefined,
        parentId: undefined,
        level: undefined,
        centerLatLon: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.areaId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const areaIds = row.areaId || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + areaIds + '的数据',
        onOk () {
          return delArea(areaIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('extra/area/export', {
            ...that.queryParam
          }, `area_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
